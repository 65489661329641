import services from "@/http";
import { http } from "@/http";
import * as types from "./mutation-types";

export const ActionGetUserPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn, status, institution, unit, master, column, noQueryMaster } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''
  const queryStatus = `&status=${status}`
  const queryMaster = !noQueryMaster ? `&master=${master}` : ''
  const queryInstitution = institution ? `&institution=${institution}` : ''
  const queryUnit = unit ? `&unit=${unit}` : ''
  const queryColumn = column ? `&column=${column}` : ''
  return http.get(`user/all?page=${currentPage}&quantity=${quantityPerPage}${queryOrderColumn}${queryOrderBy}${queryInstitution}${queryUnit}${queryStatus}${queryMaster}${querySearch}${queryColumn}`).then(r => {
    return r.body;
  });
};

export const ActionGetUserPrograms = (context, payload) => {
  /* Lista todos usuários */
  const { id, quantityPerPage, currentPage, program, company_id } = payload

  const queryProgram = program ? `&program=${program}` : ''
  const queryInstitution = company_id ? `&company_id=${company_id}` : ''
  return http.get(`user-programs/by-user/${id}?page=${currentPage}&quantity=${quantityPerPage}
  ${queryInstitution}${queryProgram}`).then(r => {
    return r.body;
  });
};

export const ActionGetTemplateDownload = (context, payload) => {
  /* Lista todos usuários */
  return services.users.getTemplateDownload().then(r => {
    return r.data;
  });
};

export const ActionGetUsuarios = (context, payload) => {
  /* Lista todos usuários */
  return services.users.all().then(r => {
    return r.data;
  });
};

export const ActionGetUserImports = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, company_id, startDate, endDate } = payload
  const queryStartDate = startDate ? `&startDate=${startDate}` : ''
  const queryEndDate = endDate ? `&endDate=${endDate}` : ''
  return http.get(`importer/user/all?page=${currentPage}&quantity=${quantityPerPage}&company_id=${company_id}${queryStartDate}${queryEndDate}`).then(r => {
    return r.body;
  });
}
export const ActionGetCompaniesUser = (context, payload) => {
  const{id} = payload
  const url = `user-company/by-user/${id}`;
  return http.get(url).then((r) => {
    return r.body;
  });
}

export const ActionUpdateUsuarioCompanie = (context, payload) => {
  return services.users.update_companie(payload).then(r => {
    return r.data;
  });
};
export const ActionGetUsersDoctor = (context, payload) => {
  /* Lista todos usuários */
  return services.users.allDoctors().then(r => {
    return r.data;
  });
};

export const ActionGetUsuario = (context, payload) => {
  /* Pega usuario por id */
  return services.users.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionGetUsuarioImport = (context, payload) => {
  /* Pega usuario por id */
  return services.users.getUserImport({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateUsuario = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`user/update/${payload.id}`, payload.form).then(r => {
    return r;
  });
};

export const ActionCreateUserInstitution = (context, payload) => {
  /* atualizar usuarios */
  return http.post(`user/create-user-institution`, payload.form).then(r => {
    return r;
  });
};



export const ActionUpdateUsuarioMe = (context, payload) => {
  /* atualizar usuarios */

  return http.put(`user/update-me`, payload.form).then(r => {
    return r;
  });
};

export const ActionUpdatePasswordLogged = (context, payload) => {
  /* atualizar usuarios */

  return http.put(`user/update-password-logged`, payload).then(r => {
    return r;
  });
};

export const ActionCreateUsuario = (context, payload) => {
  /*Criar usuario*/
  return services.users.create(payload).then(r => {
    return r;
  });
};

export const ActionCreateMultipleUsers = (context, payload) => {
  /*Criar usuario*/
  return services.users.createMultiple(payload).then(r => {
    return r;
  });
};

export const ActionGetLogs = (context, payload) => {
  /*Criar usuario*/
  return services.users.getLogs({ id: payload }).then(r => {
    return r;
  });
};


export const ActionInactiveUsuarios = (context, payload) => {
  return services.users.inactive({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionPushNotificationProgramPending = (context, payload) => {

  return http.post(`notification/send-many`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateApiKey = (context, payload) => {
  const { companyId } = payload
  return http.post(`company-api-key/${companyId}`).then(r => {
    return r.data;
  });
};

export const ActionGetApiKey = (context, payload) => {
  const { companyId } = payload
  return http.get(`company-api-key/${companyId}`).then(r => {
    return r.data;
  });
};
export const ActionDeleteApiKey = (context, payload) => {
  const { companyId } = payload

  return http.delete(`company-api-key/${companyId}`).then(r => {
    return r.data;
  });
};
export const ActionPutApiKey = (context, payload) => {
  const { companyId } = payload

  return http.put(`company-api-key/toggle-status/${companyId}`, payload).then(r => {
    return r.data;
  });
};

export const ActionSetUpdateUsuario = ({ commit }, payload) => {
  commit(types.SET_USER_EDIT, payload);
};
