import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetMedicinesRelations = (context, payload) => {

  return http.get(`medicines/get/relations`).then(r => {
    return r.body;
  });
};

export const ActionGetSubstances = (context, payload) => {
  const { search, page, quantity } = payload
  const queryPage = page ? `?page=${page}` : ''
  const queryQuantity = quantity ? `&quantity=${quantity}` : ''
  const querySearch = search ? `&search=${search}` : ''
  return http.get(`medicines/substances${queryPage}${queryQuantity}${querySearch}`).then(r => {
    return r.body;
  });
};

export const ActionGetMedicines = (context, payload) => {
  /* Lista todos usuários */
  const {
    substance
  } = payload
  const querySubstance = substance ? `?substance=${substance}` : ''


  return http.get(`medicines/collection${querySubstance}`).then(r => {
    return r.body;
  });
};

export const ActionGetMedicinesPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''


  return http.get(`medicines/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetMedicine = (context, payload) => {
  /* Pega usuario por id */
  return services.medicines.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateMedicine = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`medicines/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateMedicine = (context, payload) => {
  /*Criar usuario*/
  return services.medicines.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveMedicine = (context, payload) => {
  return services.medicines.inactive({ id: payload }).then(r => {
    return r.data;
  });
};