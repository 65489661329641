export default {
  //Buscar Todos
  all: { method: "GET", url: "companies/all" },

  // //Buscar Todos Filhos
  // all: { method: "GET", url: "companies/all-filhos" },

  //Buscar Instituição especifica por id
  get: { method: "GET", url: "companies/get{/id}" },

  getUnity: { method: "GET", url: "company-unity/get{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "companies"
  },

  createUnity: {
    method: "POST",
    url: "company-unity"
  },

  //Editar
  update: {
    method: "POST",
    url: "companies/update{/id}"
  },

  updateUnity: {
    method: "POST",
    url: "company-unity/update{/id}"
  },
  //Inativar Instituição
  inactive: { method: "DELETE", url: "companies/delete{/id}" },

  inactiveUnity: { method: "DELETE", url: "company-unity/delete{/id}" },
  //inserir telefone da instituição
  createPhone: {
    method: "POST",
    url: "company/phone"
  },

  //editar telefone da instituição
  updatePhone: {
    method: "PUT",
    url: "company/phone{/id}"
  },

  //Inserir o endereço da instituição
  createAddress: {
    method: "POST",
    url: "addresses-company"
  },

  //Editar o endereço da instituição
  editAddress: {
    method: "PUT",
    url: "addresses-company"
  },

  getAddress: {
    method: "GET",
    url: "addresses-company/get{/id}"
  },

  //Inserir contato à instituição
  createContact: {
    method: "POST",
    url: "contacts-company"
  },

  //Editar contato da instituição
  editContact: {
    method: "PUT",
    url: "contacts-company{/id}"
  },

  // get cargo all
  getCompanyCargoAll: { method: "GET", url: "company-roles/all-by-company-id{/id}" },
  // Adicionar cargo
  createCompanyCargo: { method: "POST", url: "company-roles" },
  // update cargo
  updateCompanyCargo: { method: "PUT", url: "company-roles/update{/id}" },
  // delete cargo
  deleteCompanyCargo: { method: "DELETE", url: "company-roles/delete{/id}" },


  // get setor all
  getCompanySectorAll: { method: "GET", url: "company-sectors/all-by-company-id{/id}" },
  // Adicionar setor
  createCompanySector: { method: "POST", url: "company-sectors" },
  // update setor
  updateCompanySector: { method: "PUT", url: "company-sectors/update{/id}" },
  // delete setor
  deleteCompanySector: { method: "DELETE", url: "company-sectors/delete{/id}" },

  // Adicionar cantato
  createContact: { method: "POST", url: "contacts-company" },
  //Excluir contato
  deleteContact: { method: "DELETE", url: "contacts-company/delete{/id}" },

  //Associar usuario a instituição
  connectUserInstitution: { method: "POST", url: "companies-users" },



  deleteUserInstitution: {
    method: "DELETE",
    url: "companies-users/delete/user-id{/userId}/company-id/{companyId}"
  },
  deleteUserInstitutionCompany: {
    method: "DELETE",
    url: "company-admin/delete/user-id/{userId}/company-id{/id}"
  },
  updateUserInstitution: {
    method: "PUT",
    url: "companies-users/update{/id}"
  },

  getUserInstitution: {
    method: "GET",
    url: "import-user/get{/id}"
  },
  updateUserImport: {
    method: "PUT",
    url: "import-user/update{/id}"
  },
  // getUserInstitution: {
  //   method: "GET",
  //   url: "companies-users/get{/id}"
  // },


  getUserInstitutionAll: {
    method: "get",
    url: "companies/all-imported-users{/id}"
  },
  postUserInstitution: {
    method: "post",
    url: "import-user"
  },
  disconnectUserInstitution: {
    method: "delete",
    url: "import-user/delete{/id}"
  },

};
