import { http } from "@/http";

export const ActionGetDadosBasicoProntuario = (context, payload) => {
  const {id} = payload
  const url = `medical-record/${id}`;

  return http.get(url).then((r) => {
    return r.body;
  });
};

export const ActionStoreDadosBasicoProntuario = (context, payload) => {
  const url = `medical-record`;
  return http.post(url, payload).then((r) => {
    return r.body;
  });
};

export const ActionUpdateDadosBasicoProntuario = (context, payload) => {
  const { id, form } = payload;
  const url = `medical-record/${id}`;

  return http.put(url, form).then((r) => {
    return r.body;
  });
};

export const ActionDeleteDadosBasicoProntuario = (context, payload) => {
  const { id } = payload;
  const url = `medical-record/${id}`;

  return http.delete(url).then((r) => {
    return r.body;
  });
};


export const ActionStoreFileBucket = (context, payload) => {
  const url = `medical-record/files`;

  return http.post(url, payload).then((r) => {
    return r.body;
  });
};

export const ActionStoreAditionalInfoProntuario = (context, payload) => {
  const url = `medical-record/additional-info`;

  return http.post(url, payload).then((r) => {
    return r.body;
  });
};
export const ActionUpdateAditionalInfoProntuario = (context, payload) => {
  const { id, form } = payload;
  const url = `medical-record/additional-info/${id}`;

  return http.put(url, form).then((r) => {
    return r.body;
  });
};
export const ActionDeleteAditionalInfoProntuario = (context, payload) => {
  const { id } = payload;
  const url = `medical-record/additional-info/${id}`;

  return http.delete(url).then((r) => {
    return r.body;
  });
};

export const ActionGetProntuariosPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    status,
    institution,
    unit,
    master,
  } = payload;

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : "";
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : "";
  const querySearch = search ? `&search=${search}` : "";
  const queryStatus = `&status=${status}`;
  const queryMaster = `&master=${master}`;
  const queryInstitution = institution ? `&institution=${institution}` : "";
  const queryUnit = unit ? `&unit=${unit}` : "";
  return http
    .get(
      `medical-record/all?page=${currentPage}&quantity=${quantityPerPage}${queryOrderColumn}${queryOrderBy}
  ${queryInstitution}${queryUnit}${queryStatus}${queryMaster}${querySearch}`,
    )
    .then((r) => {
      return r.body;
    });
};

