import store from "../store";
export default function (request, next) {
  next(function (response) {
    // if (response.status === 403) {
    //   window._Vue.$swal.fire({
    //     icon: "warning",
    //     title: "Aviso",
    //     text: response.body.message
    //   })
    //     .then(() => {
    //       window._Vue.$router.push({ name: "Login" });
    //     });
    // }

    if (!response.ok || response.status === 401) {
      store.dispatch("preloader/ActionSetPreloader", { status: false });
      if (response.status === 401 || response.status == 500 && response.url.includes("auth/refresh")) {
        store.dispatch("auth/ActionSingnout");
        window._Vue.$swal
          .fire({
            icon: "warning",
            title: "Aviso",
            text: response.data.message || "Sua sessão expirou, favor logar novamente no sistema",
          })
          .then(() => {
            if (window._Vue.$route.name !== 'Login') {
              window._Vue.$router.push({ name: "Login" });
            }
          });
      } else if (response.status !== 201 || response.status !== 200) {
        window._Vue.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.body.message,
        });
      }
    }
  });
}
