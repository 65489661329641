export default {
  //Buscar Todos
  all: { method: "GET", url: "user/all" },

  //Buscar Todos medicos
  allDoctors: { method: "GET", url: "user/doctor/all" },

  // Ativar
  active: { method: "GET", url: "user/active" },

  update_companie: { method: "PUT", url: "user/update-user-companies" },

  //Buscar Id
  get: { method: "GET", url: "user/get{/id}" },

  getUserImport: { method: "GET", url: "import-user/get{/id}" },

  getTemplateDownload: { method: "GET", url: "importer/user/template" },

  createMultiple: {
    method: "POST",
    url: "importer/user/store"
  },

  getLogs: {
    method: "GET",
    url: "importer/user/logs/{id}"
  },
  //Criar
  create: {
    method: "POST",
    url: "user"
  },

  //Editar
  update: {
    method: "PUT",
    url: "user/{id}"
  },
  //Inativar
  inactive: { method: "DELETE", url: "user/delete{/id}" }
};
