import services, { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetActionsPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''

  return http.get(`outputs/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetActions = (context, payload) => {
  /* Lista todos usuários */
  return services.actions.all().then(r => {
    return r.body;
  });
};

export const ActionGetAction = (context, payload) => {
  /* Pega usuario por id */
  return services.actions.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateAction = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`outputs/update/${payload.id}`, payload).then(r => {
    return r;
  });
};

export const ActionCreateAction = (context, payload) => {
  /*Criar usuario*/
  return services.actions.create(payload).then(r => {
    return r;
  });
};
export const ActionCreateMultipleAction = (context, payload) => {
  /*Criar usuario*/
  return services.actions.createMultiple(payload).then(r => {
    return r.body;
  });
};

export const ActionInactiveAction = (context, payload) => {
  return services.actions.inactive({ id: payload }).then(r => {
    return r;
  });
};
