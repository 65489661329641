import { services as auth } from "../views/Pages/Auth/Login";
import { services as users } from "../views/Pages/Admin/User";
import { services as institutions } from "../views/Pages/Admin/Institutions";
import { services as position } from "../views/Pages/Admin/Cargo";
import { services as sector } from "../views/Pages/Admin/Sector";
import { services as avail } from "../views/Pages/Admin/Avaliacao";
import { services as question } from "../views/Pages/Admin/Questions";
import { services as reply } from "../views/Pages/Admin/Answers";
import { services as programa } from "../views/Pages/Admin/Programas";
import { services as diagnostics } from "../views/Pages/Admin/Diagnostic";
import { services as actions } from "../views/Pages/Admin/Actions";
import { services as cids } from "../views/Pages/Admin/Cids";
import { services as medicines } from "../views/Pages/Admin/Medicina";
import { services as procedure } from "../views/Pages/Admin/Procedure";
import { services as programUser } from "../views/Pages/Admin/ProgramUser";

import { services as typeUser } from "../views/Pages/Admin/Type";
import { services as permissionGroups } from "../views/Pages/Admin/Group";


// ------- store area clientes ----------
// import { store as signUp } from "../views/Clients/Signup";

export default {
  programUser,
  actions,
  diagnostics,
  auth,
  users,
  institutions,
  position,
  sector,
  avail,
  question,
  reply,
  programa,
  cids,
  medicines,
  procedure,
  typeUser,
  permissionGroups
  // signUp
};
