<!-- eslint-disable vue/no-parsing-error -->
<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ '': type === 'default', 'header-main': $route.name !== 'Login' }"
  >
    <img v-if="hasToken && !$route.meta.free" class="logo" src="@/assets/images/logo-branco.png" alt="Logo" style="max-width: 230px" />
    <!-- Navbar links -->
    <b-navbar-nav v-if="hasToken && !$route.meta.free" class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </b-navbar-nav>
    <b-navbar-nav v-if="hasToken && !$route.meta.free" class="align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0 text-white"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <!-- <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="img/theme/team-4.jpg" />
            </span>
            -->
            <!-- validacao diferente de algoritmika -->
            <span class="mr-3 mb-0 text-white">&copy; {{ appVersion }}  {{
              getUser.session_company
                ? "Instituição: " + getUser.session_company.name
                : ""
            }}</span>

            <b-avatar></b-avatar>

            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold text-white">{{
                getUser.name ? getUser.name.replace(/ .*/, "") : ""
              }}</span>
            </b-media-body>
          </b-media>
        </a>
        <template>
          <b-dropdown-item href="/meus-dados">
            <i class="fas fa-address-card"></i>
            <span>Meus Dados</span>
          </b-dropdown-item>
          <b-dropdown-item href="#" @click.prevent="logout()">
            <i class="ni ni-user-run"></i>
            <span>Sair</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import { mapGetters, mapActions } from "vuex";
import { version } from "../../../package.json";
export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser", "hasToken"]),

    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      appVersion: version,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions("auth", ["ActionSingnout"]),

    async logout() {
      await this.ActionSingnout();
      this.$router.push({ name: "Login" });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>

<style scoped>
.header-main {
  /* background-image: url("../../assets/images/bg_header_adm.png"); */
  background-color: #00202B;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  border: none !important;
  align-items: flex-start !important;
}

@media (max-width: 600px) {
  .logo {
    display: none;
  }
}
</style>
