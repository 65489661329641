import services, { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetDiagnosticsPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''

  const querySearch = search ? `&search=${search}` : ''

  return http.get(`diagnostics/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetDiagnostics = (context, payload) => {
  /* Lista todos usuários */
  return services.diagnostics.all().then(r => {
    return r.body;
  });
};

export const ActionGetDiagnostic = (context, payload) => {
  /* Pega usuario por id */
  return services.diagnostics.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateDiagnostic = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`diagnostics/update/${payload.id}`, payload).then(r => {
    return r;
  });
};

export const ActionCreateDiagnostic = (context, payload) => {
  /*Criar usuario*/
  return services.diagnostics.create(payload).then(r => {
    return r;
  });
};
export const ActionCreateMultipleDiagnostic = (context, payload) => {
  /*Criar usuario*/
  return services.diagnostics.createMultiple(payload).then(r => {
    return r.body;
  });
};

export const ActionInactiveDiagnostic = (context, payload) => {
  return services.diagnostics.inactive({ id: payload }).then(r => {
    return r;
  });
};
