import services from "@/http";
import * as storage from "../storage";
import * as types from "./mutation-types";
import { http } from "@/http";

const captchaUrl = 'https://cors-anywhere.herokuapp.com/https://www.google.com/recaptcha/api/siteverify'

export const ActionGetDados = ({ dispatch }, payload) => {
  return services.auth.functionGet({ function: payload }).then(r => {
    return r.body;
  });
};
export const ActionGetFunctions = ({ dispatch }, payload) => {
  return services.auth.functionAll(payload).then(r => {
    return r.body;
  });
};

export const ActionDoLogin = ({ dispatch }, payload) => {
  delete payload.mantem;
  return services.auth.login(payload).then(r => {
    if (r.body.token) {
      let user = {}
      if (!r.body.user.session_company && r.body.user.session_type) {
        user = { ...r.body.user, session_company: { id: 1, name: "Base" } }
      }
      else {
        user = { ...r.body.user }
      }
      dispatch("ActionSetUser", user);
      dispatch("ActionSetToken", r.body.token);
      dispatch("ActionSetMantem", { ...payload.mantem });

    }
    return r.body;
  });
};
export const ActionCheckToken = ({ dispatch, state }) => {
  const token = storage.getLocalToken();
  const usuario = storage.getLocalUsuario();
  if (usuario) {
    dispatch("ActionSetUser", usuario);
  }
  if (token) {
    storage.setHeaderToken(token);
    services.auth.checkToken().then(r => {
      let user = {}
      if (!r.data.user.session_company && r.data.user.session_type) {
        user = { ...r.data.user, session_company: { id: 1, name: "Base" } }
      }
      else {
        user = { ...r.data.user }
      }
      dispatch("ActionSetUser", user);
      dispatch("ActionSetToken", r.data.token);

      if (r.data.company) {
        dispatch("ActionSetUser", { ...r.data.user, session_company: r.data.company });
      }
    });
  }

  if (state.token) {
    //   return Promise.resolve(state.token);
    // } else if (!token) {
    //   return Promise.reject(new Error("Token Inválido"));
    // } else {
    // dispatch("ActionSetToken", token);
    //   dispatch("ActionSetUser", storage.getLocalUsuario());
    //   return Promise.resolve(token);
  }
};

export const ActionSetUser = ({ commit }, payload) => {
  storage.setLocalUsuario(JSON.stringify(payload));

  commit(types.SET_USER, payload);
};

export const ActionSetMantem = ({ commit }, payload) => {
  commit(types.SET_MANTEM_CONECTADO, payload);
};

export const ActionSetMantemConectado = ({ commit }, payload) => {
  commit(types.SET_MANTEM_CONECTADO, payload);
};

export const ActionSetToken = ({ commit }, payload) => {
  storage.setLocalToken(payload);
  storage.setHeaderToken(payload);
  commit(types.SET_TOKEN, payload);
};
export const ActionSingnout = ({ dispatch }) => {
  storage.setHeaderToken("");
  storage.deleteLocalToken();
  storage.deleteLocalUsuario();
  dispatch("ActionSetUser", {});
  dispatch("ActionSetToken", "");
};

export const ActionForgotPassword = (context, payload) => {
  return services.auth.forgotPass(payload).then(r => {
    return r.data;
  });
};

export const ActionGetClientes = (context, payload) => {
  return services.auth.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionValidateFirstAcess = (context, payload) => {
  return services.auth.validateFirstAcess({ documentOrEmail: payload }).then(r => {
    return r.data;
  });
};

export const ActionGetPublicKey = (context, payload) => {
  return http.get(`auth/public-key`, payload).then(r => {
    return r.data;
  });
};


export const ActionGenerateImpersonationToken = (context, payload) => {
  return http.post(`auth/empersonar`, payload).then(r => {
    return r.data;
  });
};

export const ActionGetTokenFirstAccess = (context, payload) => {
  return http.get(`user/first-access/${payload.login}?captchaToken=${payload.captchaToken}`).then(r => {
    return r.data;
  });
};

export const ActionValidateTokenFirstAccess = (context, payload) => {
  return http.get(`user/first-access/validate/${payload.token}`).then(r => {
    return r.data;
  });
};
export const ActionUpdateFirstAccess = (context, payload) => {
  return http.put(`user/first-access/update/${payload.token}?captchaToken=${payload.captchaToken}`, payload.user).then(r => {
    return r.data;
  });
};

export const ActionGetTokenForgotPassword = (context, payload) => {
  return http.put(`user/forgot-password/${payload.login}?captchaToken=${payload.captchaToken}`).then(r => {
    return r.data;
  });
};
export const ActionValidateTokenForgotPassword = (context, payload) => {
  return http.get(`user/forgot-password/validate/${payload.token}`).then(r => {
    return r.data;
  });
};
export const ActionUpdateForgotPassword = (context, payload) => {
  return http.put(`user/forgot-password/update/${payload.token}?captchaToken=${payload.captchaToken}`, payload.newPassword).then(r => {
    return r.data;
  });
};

// unidade criando usuario com codigo
export const ActionCreateCodigo = (context, payload) => {
  return http.post(`company-codes`, payload).then(r => {
    return r.data;
  });
};

export const ActionCheckCaptcha = (context, payload) => {
  return http.post(captchaUrl + `?secret=6Lc3z_8nAAAAAGfvrkH35EuzIcZSBNkFm-eov4oe&response=${payload.response}`).then(r => {
    return r.data;
  });
};

export const ActionUpdateCodigo = (context, payload) => {
  return http.put(`company-codes/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};
export const ActionDeleteCodigo = (context, payload) => {
  return http.delete(`company-codes/delete/${payload.code_id}`).then(r => {
    return r.data;
  });
};

export const ActionGetCode = (context, payload) => {
  return http.get(`company-codes/get-by-code/${payload.code}`).then(r => {
    return r.data;
  });
};

export const ActionListAllCodes = (context, payload) => {
  return http.get(`company-codes/all-by-company-id/${payload.company_id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateByCode = (context, payload) => {
  return http.post(`user/first-access/createByCode/${payload.code}`, payload.user).then(r => {
    return r.data;
  });
};
