import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetTypesPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''


  return http.get(`type-user/paginate?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};


export const ActionGetTypesAll = (context, payload) => {
  /* Lista todos usuários */
  return http.get(`type-user/all`).then(r => {
    return r.body;
  });
};
export const ActionGetType = (context, payload) => {
  /* Pega usuario por id */
  return services.typeUser.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateType = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`type-user/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateType = (context, payload) => {
  /*Criar usuario*/
  return services.typeUser.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveType = (context, payload) => {
  return services.typeUser.inactive({ id: payload }).then(r => {
    return r.data;
  });
};