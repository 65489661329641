import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetRelatorioIndividual = (context, payload) => {
  /* Lista todos usuários */
  const {
    user_id,
    program_id,
    result_id,
  } = payload
  const queryUser_id = user_id ? `user_id=${user_id}` : ''
  const queryProgram_id = program_id ? `&program_id=${program_id}` : ''
  // const queryResult_id = result_id ? `&availDate=${result_id}` : ''
  const queryResult_id = result_id ? `&result_id=${result_id}` : ''

  return http.get(`avails-result/get/report/individual?${queryUser_id}${queryProgram_id}${queryResult_id}`).then(r => {
    return r.body;
  });
};

export const ActionUpdateObservacaoLaudo = (context, payload) => {
  /* Lista todos usuários */
  const {
    result_id,
    body
  } = payload

  return http.put(`avails-result/update/report/individual/${result_id}`, body).then(r => {
    return r.body;
  });
};

export const ActionUpdateObservacaoInterna = (context, payload) => {
  /* Lista todos usuários */
  const {
    result_id,
    body
  } = payload

  return http.put(`avails-result/update/report/internal/${result_id}`, body).then(r => {
    return r.body;
  });
};

export const ActionDownloadLaudo = (context, payload) => {
  /* Lista todos usuários */
  const {
    program_id,
    user_id,
    result_id
  } = payload
  return http.post(`avails-result/pdf?user_id=${user_id}&program_id=${program_id}&result_id=${result_id}`).then(r => {
    return r.body;
  });
};

export const ActionDownloadPedidos = (context, payload) => {
  /* Lista todos usuários */
  const {
    program_id,
    user_id,
    result_id
  } = payload
  return http.post(`avails-result/exames-pdf?user_id=${user_id}&program_id=${program_id}&result_id=${result_id}`).then(r => {
    return r.body;
  });
};

export const ActionDownloadPrescricoes = (context, payload) => {
  /* Lista todos usuários */
  const {
    program_id,
    user_id,
    result_id
  } = payload
  return http.post(`avails-result/prescricao-pdf?user_id=${user_id}&program_id=${program_id}&result_id=${result_id}`).then(r => {
    return r.body;
  });
};
