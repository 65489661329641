import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetRepliesPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''

  const querySearch = search ? `&search=${search}` : ''

  return http.get(`replies/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};


export const ActionGetReplies = (context, payload) => {
  /* Lista todos reply */
  return services.reply.all().then(r => {
    return r.data;
  });
};

export const ActionGetReply = (context, payload) => {
  /* Pega usuario por id */
  return services.reply.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateReply = (context, payload) => {
  /* atualizar reply */
  return http.put(`replies/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateReply = (context, payload) => {
  /*Criar reply*/
  return services.reply.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveReply = (context, payload) => {
  return services.reply.inactive({ id: payload }).then(r => {
    return r.data;
  });
};
