import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetQuestionsPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''

  return http.get(`questions/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetQuestions = (context, payload) => {
  /* Lista todos usuários */
  return services.question.all().then(r => {
    return r.data;
  });
};

export const ActionGetQuestion = (context, payload) => {
  /* Pega question por id */
  return services.question.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateQuestion = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`questions/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateQuestion = (context, payload) => {
  /*Criar question*/
  return services.question.create(payload).then(r => {
    return r.data;
  });
};
export const ActionCreateMultiple = (context, payload) => {
  /*Criar question*/
  return services.question.createMultiple(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveQuestion = (context, payload) => {
  return services.question.inactive({ id: payload }).then(r => {
    return r.data;
  });
};
