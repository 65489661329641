import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetProgramasPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } =
    payload;

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : "";
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : "";
  const querySearch = search ? `&search=${search}` : "";

  return http
    .get(
      `programs/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`,
    )
    .then((r) => {
      return r.body;
    });
};

export const ActionGetProgramUsersPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    idProgram,
    company_id,
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload;
  const quantityPerPageQuery = quantityPerPage
    ? `&quantity=${quantityPerPage}`
    : "";
  const currentPageQuery = currentPage ? `?page=${currentPage}` : "";
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : "";
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : "";
  const querySearch = search ? `&search=${search}` : "";
  const queryCompanyId = company_id ? `&company_id=${company_id}` : "";
  return http
    .get(
      `user-programs/get-user/${idProgram}${currentPageQuery}${queryCompanyId}${quantityPerPageQuery}${querySearch}${queryOrderColumn}${queryOrderBy}`,
    )
    .then((r) => {
      return r.body;
    });
};

export const ActionGetProgramaInstituicoes = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    program_id,
    company_id,
  } = payload;

  const quantityPerPageQuery = quantityPerPage
    ? `&quantity=${quantityPerPage}`
    : "";
  const currentPageQuery = currentPage ? `?page=${currentPage}` : "";
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : "";
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : "";
  const querySearch = search ? `&search=${search}` : "";
  const id = program_id ? program_id : `companies/${company_id}`;
  return http
    .get(
      `company-programs/all/${id}${currentPageQuery}${quantityPerPageQuery}${querySearch}${queryOrderColumn}${queryOrderBy}`,
    )
    .then((r) => {
      return r.body;
    });
};

export const ActionGetProgramas = (context, payload) => {
  /* Lista todos instituições */

  return services.programa.all().then((r) => {
    return r.body;
  });
};

export const ActionGetPrograma = (context, payload) => {
  /* Pega usuario por id */
  return services.programa.get({ id: payload }).then((r) => {
    return r.data;
  });
};

export const ActionGetProgramaLimited = (context, payload) => {
  /* Pega programa por id com dados basicos*/
  return services.programa.getLimited({ id: payload }).then((r) => {
    return r.data;
  });
};

export const ActionGetProgramaInstituicao = (context, payload) => {
  /* Pega usuario por id */
  return services.programa.getProgramInstitution({ id: payload }).then((r) => {
    return r.data;
  });
};

export const ActionGetProgramFrequency = (context, payload) => {
  /* Pega usuario por id */
  return services.programa.getProgramFrequency({ id: payload }).then((r) => {
    return r.data;
  });
};

export const ActionUpdatePrograma = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`programs/update/${payload.id}`, payload.form).then((r) => {
    return r;
  });
};
export const ActionGetProgramaFrequency = (context, id) => {
  /* atualizar usuarios */
  return http.get(`company-programs/frequency/${id}`).then((r) => {
    return r;
  });
};

export const ActionUpdateProgramInstitution = (context, payload) => {
  /* atualizar usuarios */
  return http
    .put(`company-programs/update/${payload.id}`, payload.form)
    .then((r) => {
      return r;
    });
};

export const ActionCreatePrograma = (context, payload) => {
  /*Criar usuario*/
  return services.programa.create(payload).then((r) => {
    return r;
  });
};

export const ActionCreateProgramaInstitution = (context, payload) => {
  /*Criar usuario*/
  return services.programa.createProgramInstitution(payload).then((r) => {
    return r;
  });
};

export const ActionInactivePrograma = (context, payload) => {
  return services.programa.inactive({ id: payload }).then((r) => {
    return r;
  });
};

export const ActionInactiveProgramInstitution = (context, payload) => {
  return services.programa
    .inactiveProgramInstitution({ id: payload })
    .then((r) => {
      return r;
    });
};
export const ActionLinkUsersPrograma = (context, payload) => {
  return services.programa.linkUsers(payload).then((r) => {
    return r;
  });
};

export const ActionConsentimentoPrograma = (context, payload) => {
  return http.put(`programs/consent/${payload.id}`, payload.form).then((r) => {
    return r;
  });
};

export const ActionGetPrograms = (context, payload) => {
  return http.get(`programs/get/${payload.id}`).then((r) => {
    return r;
  });
};
