export default {
  //Buscar Todos
  all: { method: "GET", url: "outputs/all" },

  // Ativar
  // active: { method: "GET", url: "position/active" },

  //Buscar Id
  get: { method: "GET", url: "outputs/get{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "outputs"
  },
  //Criar multiple
  createMultiple: {
    method: "POST",
    url: "outputs/many"
  },

  //Editar
  update: {
    method: "PUT",
    url: "outputs/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "outputs/delete{/id}" }
};
