import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import {
  getLocalToken,
  getLocalUsuario
} from "../views/Pages/Auth/Login/storage";
import store from "../store";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: "history"
});


function getScreens(user) {
  let screensList = [];

  let { types } = user;

  if (types)
    types.map(type => {
      type.permissions.map(group => {

        group.functionalities.map(funcy => {

          let { screens } = funcy;

          if (!screensList.find(f => screens.web.filter(x => x == f).length > 0)) {
            screensList = screensList.concat(screens.web);
          }

          if (!screensList.find(f => screens.webClient.filter(x => x == f).length > 0)) {
            screensList = screensList.concat(screens.webClient);
          }
        });

      })


    });

  screensList = screensList.filter((value, index, array) => {
    return array.indexOf(value) === index;
  })
  return screensList;
}

function compareWithParams(path, router) {
  if (path.includes(":")) {
    Object.keys(router.params).map(x => {

      path = String(path).replace(`:${x}`, router.params[x]);
    });
    return path == router.path;
  } else {
    return path == router.path;
  }
}
/// VALIDAR TYPE FUCIONALIDADE - CRUDI
/// C - CREATE
/// R - READ
/// U - UPDATE
/// D- DELETE
/// I - IMPORT
router.beforeEach(async (to, from, next) => {
  // document.title = `${process.env.VUE_APP_NOME} | ${to.name}`;
  let token = getLocalToken();
  let user = getLocalUsuario() || {};
  const screans = getScreens(user);

  // const permission = user.permissions ? user.permissions.map(permission => (permission.name)) : []
  if (to.path === '/' && !token) {
    next("/login");
   }
  if (to.path === '/' && token) {
    next(true);
   }
  else if (to.path === '/login' && token) {
    next('/')
   }

  else if (to.meta.auth && !token && from.path !== '/login') {
    next("/login");
  }
  else if (user.session_type == 'comum' && (user.types || []).find(f => f.is_client) && to.meta.admin) {
    next("/clients");
  } else if (user.session_type == 'comum' && to.path == '/clients/home' && (user.types || []).find(f => f.is_client)) {
    next(true);
  } else if (to.meta.free) {
    next(true);
  }
  else if (to.meta.free || screans.find(f => compareWithParams(f, to)) || (user.types || []).find(f => f.is_admin)) {
    next(true);
  }
});

export default router;
