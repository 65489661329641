export default {
  //Buscar Todos
  all: { method: "GET", url: "questions/all" },

  // Ativar
  // active: { method: "GET", url: "questions/active" },

  //Buscar Id
  get: { method: "GET", url: "questions/get{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "questions"
  },
  //Criar varias
  createMultiple: {
    method: "POST",
    url: "questions/multiple"
  },

  //Editar
  update: {
    method: "PUT",
    url: "questions/update/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "questions/delete{/id}" }
};
