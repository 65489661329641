<template>
  <div class="d-flex flex-column flex-md-row items-center area-acess external">
    <div class="flex-1 d-flex align-items-center justify-content-center px-2 order-2 order-md-1 area-bg"
      style="background-image: url(/img/bg-login.png); background-repeat: no-repeat; background-size: cover; background-position: center center;">
      <p class="msg-login">Capacitar pessoas a viverem vidas mais saudáveis e felizes</p>
    </div>
    <div class="area-form d-flex align-items-center order-md-1 overflow-auto h-100">
      <b-card-body class="px-lg-5 py-lg-5 card-form mx-auto h-100">
        <img style="width: 125px; margin-bottom: 40px;" class="" src="@/assets/images/logo.png" alt="Logo" />

        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </b-card-body>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition,
  },
};
</script>

<style scoped>
.area-acess {
  overflow-Y: hidden;
  height: 100vh
}

p {
  line-height: 1.5;
}

.area-form {
  width: 100%;
  max-width: 801px;
  border-radius: 0px 0px 16px 16px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.area-bg {
  position: relative;
  z-index: 1;
}

.card-body a,
.card-body a small {
  color: #02214a;
  font-weight: 600;
}

.msg-login {
  font-size: 60px;
  color: #fff;
  font-weight: 300;
  max-width: 611px;
  margin: 0 auto;
}

.card-form {
  max-width: 493px;
}

@media (max-width: 1536px) {
  .msg-login {
    font-size: 36px;
    max-width: 448px;
  }
}

@media (max-width: 1280px) {
  .msg-login {
    width: 386px;
  }
}

@media (max-width: 768px) {
  .msg-login {
    max-width: 362px;
    margin-top: 170px;
    margin-bottom: 100px;
  }

  .area-acess {
    overflow-Y: hidden;
    height: auto;
  }

  .area-bg {
    margin-top: -100px;
  }
}
</style>
