import Vue from "vue";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: POSITION.BOTTOM_RIGHT
};

Vue.use(Toast, options);
