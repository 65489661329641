<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, validated }">
    <div class="form-group">
      <slot name="label">
        <label v-if="label" :class="[labelClasses,
          { 'text-red-700': errors[0] },]" style="font-weight: 500;">
          {{ label }}
        </label>
      </slot>
      <div :class="[
        { 'border wrapper-input': !isSlot },
        { 'ex-border-gray-300 ex-bg-gray-50': !errors[0] },
        { 'border-red-500 bg-red-50': errors[0] },
        { 'input-group': hasIcon },
        { focused: focused },
        { 'input-group-alternative': alternative },
        { 'has-label': label || $slots.label },
        inputGroupClasses,
      ]">
        <div v-if="prependIcon || $slots.prepend" class="input-group-prepend border-0" :class="[{'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]">
          <span class="input-group-text border-0" :class="[{'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]">
            <slot name="prepend">
              <i :class="prependIcon"></i>
            </slot>
          </span>
        </div>
        <slot v-bind="slotData">
          <input :value="value" :type="type" v-on="listeners" v-bind="$attrs" :valid="valid" :required="required"
            class="form-control text-gray-900 border-0" :class="[{ 'text-uppercase': uppercase }, {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]" contenteditable="true"
            />
        </slot>
        <div v-if="appendIcon || $slots.append" :class="['input-group-append border-0', {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]">
          <span :class="['input-group-text border-0', {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]">
            <slot name="append">
              <i :class="appendIcon"></i>
            </slot>
          </span>
        </div>
        <slot name="infoBlock"></slot>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{ successMessage }}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback text-red-600" style="display: block">
          {{ errors[0] }}
        </div>
      </slot>
    </div>
  </validation-provider>
</template>
<script>
import { extend } from "vee-validate/dist/vee-validate.full";
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "A confirmação da senha não corresponde",
});

export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      description:
        "Whether input is an input group (manual override in special cases)",
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    uppercase: {
      type: Boolean,
      description: "Deixa o texto digitado em caixa alta",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label text-gray-900",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    type: {
      type: String,
      description: "Input type",
      default: "text",
    },
    appendIcon: {
      type: String,
      description: "Append icon (right)",
    },
    prependIcon: {
      type: String,
      description: "Prepend icon (left)",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    },
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
  },
};
</script>
<style>
.input-group-prepend,
.input-group-prepend .input-group-text {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.input-group-append,
.input-group-append .input-group-text {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.wrapper-input, .wrapper-input input {
  border-radius: 8px;
}
</style>
