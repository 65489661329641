import services from "@/http";
import { http } from "@/http";
import * as types from "./mutation-types";

export const ActionGetAvailsPagination = (context, payload) => {
  /* Lista todos usuários */
  const { quantityPerPage, currentPage, search, orderBy, orderColumn } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''

  return http.get(`avails/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetAvails = (context, payload) => {
  /* Lista todos avail */

  return services.avail.all().then(r => {
    context.commit(types.SET_AVAILS, r.data)
    return r.data;
  });
};

export const ActionGetAvail = (context, payload) => {
  /* Pega avail por id */
  return services.avail.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionGetGamesList = (context, payload) => {
  const url = "games/all";
  return http.get(url).then((r) => {
    return r.data;
  });
};

export const ActionGetConfigVars= (context, payload) => {
  /* Pega avail por id */
  return services.avail.getConfigVars({ id: payload }).then(r => {
    return r.data;
  });
};
export const ActionPutConfigVars= (context, payload) => {
  return http.put(`avail/config/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionUpdateAvail = (context, payload) => {
  /* atualizar avail */
  return http.put(`avails/update/${payload.id}`, payload.form).then(r => {
    return r.data;
  });
};

export const ActionCreateAvail = (context, payload) => {
  /*Criar avail*/
  return services.avail.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveAvail = (context, payload) => {
  return services.avail.inactive({ id: payload }).then(r => {
    return r.data;
  });
};

// -------------- questoes -------------

export const ActionCreateQuestion = (context, payload) => {
  /*Criar question*/
  return services.avail.createQuestion(payload).then(r => {
    return r.data;
  });
};

export const ActionGetQuestionsAll = (context, payload) => {
  /* Lista todos question */
  return services.avail.allQuestion().then(r => {
    return r.data;
  });
};

export const ActionGetQuestion = (context, payload) => {
  /* Pega question por id */
  return services.avail.getQuestion({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateQuestion = (context, payload) => {
  /* atualizar question */
  return http.put(`avail/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveQuestion = (context, payload) => {
  return services.avail.inactiveQuestion({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionConnectReplyQuestion = (context, payload) => {
  return services.avail.connectReplyQuestion(payload).then(r => {
    return r.data;
  });
};

export const ActionSaveMongo = (context, payload) => {
  return services.avail.saveMongo(payload).then(r => {
    return r.data;
  });
};

export const ActionGetAvailsMongo = context => {
  return services.avail.getAvailsMongo().then(r => {
    return r.data;
  });
};
