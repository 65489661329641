<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, validated }"
  >
    <div class="form-group">
      <div class="area-input">
        <label v-if="label" :class="labelClasses" class="mb-0" :for="idinput">
          {{ label }}
        </label>

        <slot v-bind="slotData">
          <input
            :style="{ textAlign: inputAlign ? inputAlign : 'right' }"
            :id="idinput"
            :value="value"
            :type="type"
            v-on="listeners"
            v-bind="$attrs"
            :valid="valid"
            :required="required"
            class="flat-input"
            :class="{ 'text-uppercase': uppercase }"
            contenteditable="true"
          />
        </slot>

        <slot name="infoBlock"></slot>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{ successMessage }}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block">
          {{ errors[0] }}
        </div>
      </slot>
    </div>
  </validation-provider>
</template>
<script>
import { extend } from "vee-validate/dist/vee-validate.full";
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "A confirmação da senha não corresponde",
});

export default {
  inheritAttrs: false,
  name: "FlatInput",
  props: {
    inputAlign: {
      type: String,
      description: "Input align",
      default: "right",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    group: {
      type: Boolean,
      description:
        "Whether input is an input group (manual override in special cases)",
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    uppercase: {
      type: Boolean,
      description: "Deixa o texto digitado em caixa alta",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    type: {
      type: String,
      description: "Input type",
      default: "text",
    },
    appendIcon: {
      type: String,
      description: "Append icon (right)",
    },
    prependIcon: {
      type: String,
      description: "Prepend icon (left)",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {
      focused: false,
      idinput: self.crypto.randomUUID(),
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    },
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
  },
};
</script>
<style>
.flat-input {
  background-color: transparent;
  border: none;
  width: 100%;
}
.area-input:has(.flat-input:disabled) {
  background-color: #cccccc30;
  cursor: not-allowed;
}
.flat-input:disabled {
  cursor: not-allowed;
}
.area-input {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 7px;
  padding-bottom: 7px;
  border-bottom: solid 1px #02214a33;
  transition: all 0.3s;
}
.area-input:has(.flat-input:focus) {
  border-bottom: solid 1px #00a0e3;
}
form {
  flex: 1;
}
label {
  color: #02214a;
  white-space: nowrap;
}
</style>
