import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetProceduresPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''


  return http.get(`procedures/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetProcedures = (context, payload) => {
  /* Lista todos usuários */

  return http.get(`procedures/collection`).then(r => {
    return r.body;
  });
};

export const ActionGetProcedure = (context, payload) => {
  /* Pega usuario por id */
  return services.procedure.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateProcedure = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`procedures/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateProcedure = (context, payload) => {
  /*Criar usuario*/
  return services.procedure.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveProcedure = (context, payload) => {
  return services.procedure.inactive({ id: payload }).then(r => {
    return r.data;
  });
};