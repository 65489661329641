<template>
  <div>
    <router-view></router-view>
    <pre-loader :loading="getPreloader.loading" :text="getPreloader.text" />
  </div>
</template>

<script>
import PreLoader from "./components/PreLoader/PreLoader.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    PreLoader,
  },
  computed: {
    ...mapGetters("preloader", ["getPreloader"]),
  },

  methods: {
    ...mapActions("auth", ["ActionCheckToken"]),
    ...mapActions("infoRoute", ["ActionSetRotaAnterior"]),
  },

  mounted(){
    if (process.env.VUE_APP_URL_ENVOIRMENT === 'PRODUCTION') {
      var recaptchaScript = document.createElement('script');
      recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=6Lc3z_8nAAAAAOt3F1WpXMeg9Oxn576chbtxAJui';
      document.body.appendChild(recaptchaScript);
    }
  },

  beforeMount() {
    this.ActionCheckToken();
  },
  watch: {
    $route(to, from) {
      this.ActionSetRotaAnterior(from)
    },
  },
};
</script>
