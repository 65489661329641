var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, validated }){return [_c('div',{staticClass:"form-group"},[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:[_vm.labelClasses,
        { 'text-red-700': errors[0] },],staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{class:[
      { 'border wrapper-input': !_vm.isSlot },
      { 'ex-border-gray-300 ex-bg-gray-50': !errors[0] },
      { 'border-red-500 bg-red-50': errors[0] },
      { 'input-group': _vm.hasIcon },
      { focused: _vm.focused },
      { 'input-group-alternative': _vm.alternative },
      { 'has-label': _vm.label || _vm.$slots.label },
      _vm.inputGroupClasses,
    ]},[(_vm.prependIcon || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend border-0",class:[{'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]},[_c('span',{staticClass:"input-group-text border-0",class:[{'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]},[_vm._t("prepend",function(){return [_c('i',{class:_vm.prependIcon})]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control text-gray-900 border-0",class:[{ 'text-uppercase': _vm.uppercase }, {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }],attrs:{"type":_vm.type,"valid":valid,"required":_vm.required,"contenteditable":"true"},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),(_vm.appendIcon || _vm.$slots.append)?_c('div',{class:['input-group-append border-0', {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]},[_c('span',{class:['input-group-text border-0', {'bg-transparent': !errors[0] }, { 'bg-red-50': errors[0] }]},[_vm._t("append",function(){return [_c('i',{class:_vm.appendIcon})]})],2)]):_vm._e(),_vm._t("infoBlock")],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback text-red-600",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }