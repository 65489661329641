export default {
  //Buscar Todos
  all: { method: "GET", url: "cids/all" },

  //Buscar Id
  get: { method: "GET", url: "cids/get{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "cids"
  },

  //Editar
  update: {
    method: "PUT",
    url: "cids/update/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "cids/delete{/id}" }
};
