import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetRolesPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    company_id,
    sector,
    unit
  } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''
  const idQuery = company_id ? `&company_id=${company_id}` : ''
  const querySector = sector ? `&sector=${sector}` : ''
  const queryUnit = unit ? `&unit=${unit}` : ''


  return http.get(`company-roles/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}${idQuery}${querySector}${queryUnit}`).then(r => {
    return r.body;
  });
};

export const ActionGetPositions = (context, payload) => {
  /* Lista todos usuários */
  return services.position.all().then(r => {
    return r.body;
  });
};

export const ActionSelectAll = (context, payload) => {
  /* Listagem dos cargos e setores */
  const idCompanie = payload ? payload.id ? payload.id.length > 0 ? `companies=${payload.id.join(',')}` : '' : '' : ''
  return http.get(`company-roles/all-select?${idCompanie}`).then(r => {
    return r.body;
  });
};

export const ActionGetPosition = (context, payload) => {
  /* Pega usuario por id */
  return services.position.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdatePosition = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`company-roles/update/${payload.id}`, payload).then(r => {
    return r;
  });
};

export const ActionCreatePosition = (context, payload) => {
  /*Criar usuario*/
  return services.position.create(payload).then(r => {
    return r;
  });
};

export const ActionInactivePosition = (context, payload) => {
  return services.position.inactive({ id: payload }).then(r => {
    return r;
  });
};
export const ActionUploadExcel = (context, payload) => {
  const {
    company_id,
    sector_id,
    file
  } = payload
  const formData = new FormData()
  formData.append('data', file)
  return http.post(`company-roles/create/lote/company/${company_id}/sector/${sector_id}`, formData).then(r => {
    return r;
  });
};