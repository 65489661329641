import services from "@/http";
import { http } from "@/http";
import * as types from "./mutation-types";

export const ActionGetProgramUsersPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''

  return http.get(`user-programs/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetListUser = (context, payload) => {
  /* Lista todos setores e cargos da instituição */
  const { company } = payload
  return http.get(`user-programs/get-list-user/${company}`).then(r => {
    return r.body;
  });
};

export const ActionGetUsersProgram = (context, payload) => {
  /* Lista todos usuários */
  const { program_id, company_id } = payload
  return http.get(`user-programs/get-user-form/${program_id}/${company_id}`).then(r => {
    return r.body;
  });
};

export const ActionGetAgendamentos = (context, payload) => {
  /* Lista todos usuários */
  const { program_id, company_id } = payload
  return http.get(`user-programs/get-schedule/${program_id}/${company_id}`).then(r => {
    return r.body;
  });
};

export const ActionDeleteAgendamento = (context, payload) => {
  /* Lista todos usuários */
  const { agendaId } = payload
  return http.delete(`user-programs/deleted/${agendaId}`).then(r => {
    return r.body;
  });
};

export const ActionGetUsersSchedulePagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    id,
    sector,
    role,
    institution,
    unit
  } = payload

  const querySearch = search ? `&name=${search}` : ''
  const querySector = sector ? `&sector_id=${sector}` : ''
  const queryRole = role ? `&role_id=${role}` : ''
  const queryInstitution = institution ? `&institution=${institution}` : ''
  const queryUnit = unit ? `&unit=${unit}` : ''
  return http.get(`user-programs/get-users/${id}?page=${currentPage}&quantity=${quantityPerPage}${querySector}${queryRole}
  ${queryInstitution}${queryUnit}${querySearch}`).then(r => {
    return r.body;
  });
};
export const ActionPostSchedule = (context, payload) => {
  /* atualizar usuarios */
  return http.post(`user-programs/schedule`, payload).then(r => {
    return r.data;
  });
};
export const ActionUpdateSchedule = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`user-programs/update`, payload).then(r => {
    return r.data;
  });
};
export const ActionGetSchedule = (context, payload) => {
  /* get agenda by id */
  return http.get(`user-programs/find-one/${payload.agenda_id}`).then(r => {
    return r.data;
  });
};
export const ActionPostUsers = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`user-programs/update-users`, payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveProgramUser = (context, payload) => {
  /* atualizar usuarios */
  const { user_id, company_id, program_id } = payload
  return http.delete(`user-programs/delete/${user_id}/${company_id}/${program_id}`).then(r => {
    return r.data;
  });
};

export const ActionSetCompanyAgenda = ({ commit }, payload) => {
  commit(types.SET_AGENDACOMPANY, payload);
};
export const ActionSetProgramAgenda = ({ commit }, payload) => {
  commit(types.SET_PROGRAM_AGENDA, payload);
};
