<template>
 <div class="destac-title"></div>
</template>
<script>
  export default {
    name: "destacTitle",
  };
</script>
<style scoped>
.destac-title{
  background-color: #00a0e3;
  height: 7px;
  width: 32px;
  border-radius: 8px;
  margin-bottom: 32px;
}
</style>
