import services from "@/http";
import {
  http
} from "@/http";
import {
  id
} from "date-fns/locale";
import * as types from "./mutation-types";

export const ActionGetListInstitutionsPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn
  } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''

  return http.get(`companies/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetListUnitiesPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    id
  } = payload

  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''
  const idQuery = id ? `&id_companie=${id}` : ''
  return http.get(`company-unity/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}${idQuery}`).then(r => {
    return r.body;
  });
};

export const ActionGetListInstitutions = (context, payload) => {
  let querySelect = ''
  let queryAttributes = ''
  if (payload) {
    if (payload.isSetor) {
      queryAttributes = "attributes=id,tipo,name&"
    } else {
      querySelect = 'select=true'
    }
  }
  return http.get(`companies/all?${querySelect}${queryAttributes}`).then(r => {
    return r.body;
  });
};

export const ActionGetListInstitutionsSelect = (context, payload) => {
  const { company_id } = payload
  const queryCompanyId = company_id ? `?company_id=${company_id}` : ''
  return http.get(`companies/all/select${queryCompanyId}`).then(r => {
    return r.body;
  });
};

export const ActionGetInstitution = (context, payload) => {
  /* Pega Institution por id */
  return services.institutions.get({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionGetUnity = (context, payload) => {
  /* Pega Institution por id */
  return services.institutions.getUnity({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionUpdateInstitution = (context, payload) => {
  /* atualizar Institutions */
  return http
    .post(`companies/update/${payload.id}`, payload.data)
    .then(r => {
      return r.data;
    });
};

export const ActionUpdateUnity = (context, payload) => {
  /* atualizar Institutions */
  return http
    .put(`company-unity/update/${payload.id}`, payload.data)
    .then(r => {
      return r.data;
    });
};

export const ActionCreateInstitution = (context, payload) => {
  /*Criar Institution*/
  return services.institutions.create(payload).then(r => {
    return r.data;
  });
};

export const ActionCreateUnity = (context, payload) => {
  /*Criar Institution*/
  return services.institutions.createUnity(payload).then(r => {
    return r.data;
  });
};

export const ActionCreateAddress = (context, payload) => {
  /*Criar endereço Institution*/

  return services.institutions.createAddress(payload.address).then(r => {
    return r.data;

  });
};

export const ActionUpdateAddress = (context, payload) => {
  /*Editar endereço Institution*/
  return http.put(`addresses-company/update/${payload.id}`, payload.address).then(r => {
    return r.data;
  });
};

export const ActionCreatePhones = (context, payload) => {
  /*criar phone Institution*/
  return services.institutions.createPhone(payload).then(r => {
    return r.data;
  });
};

export const ActionUpdatePhones = (context, payload) => {
  /*Editar phone Institution*/
  return services.institutions.updatePhone().then(r => {
    return r.data;
  });
};

export const ActionCreateContact = (context, payload) => {
  /*Editar phone Institution*/
  return services.institutions.createContact(payload).then(r => {
    return r.data;
  });
};

export const ActionUpdatecontact = (context, payload) => {
  /*Editar contato Institution*/
  return http.put(`contacts-company/update/${payload.id}`, payload.contact).then(r => {
    return r.data;
  });
};

export const ActionInactiveContact = (context, payload) => {
  return services.institutions.deleteContact({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionInactiveInstitutions = (context, payload) => {
  return services.institutions.inactive({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionInactiveUnity = (context, payload) => {
  return services.institutions.inactiveUnity({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionConnectUserInstitution = (context, payload) => {
  return services.institutions
    .connectUserInstitution(payload)
    .then(r => {
      return r.data;
    });
};

export const ActionPostUserInstitution = (context, payload) => {
  return services.institutions
    .postUserInstitution(payload)
    .then(r => {
      return r.data;
    });
};

export const ActionGetUserInstitution = (context, payload) => {
  return services.institutions
    .getUserInstitution({
      id: payload
    })
    .then(r => {
      return r.data;
    });
};
export const ActionUpdateUserImport = (context, payload) => {
  return services.institutions
    .updateUserImport({
      id: payload.id
    }, payload)
    .then(r => {
      return r.data;
    });
};
export const ActionGetUserInstitutionAll = (context, payload) => {
  return services.institutions
    .getUserInstitutionAll({
      id: payload
    })
    .then(r => {
      return r.data;
    });
};

export const ActionGetUserInstitutionPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    id,
    sector,
    role,
    institution,
    unit,
    status
  } = payload
  const querySearch = search ? `&search=${search}` : ''
  const querySector = sector ? `&sector=${sector}` : ''
  const queryRole = role ? `&role=${role}` : ''
  const queryInstitution = institution ? `&institution=${institution}` : ''
  const queryUnit = unit ? `&unit=${unit}` : ''
  const queryStatus = `&status=${status}`
  return http.get(`companies/all-imported-users/${id}?page=${currentPage}&quantity=${quantityPerPage}${querySector}${queryRole}
  ${queryInstitution}${queryUnit}${querySearch}${queryStatus}`).then(r => {
    return r.body;
  });
};

export const ActionDisconnectUserInstitution = (context, payload) => {
  return services.institutions
    .disconnectUserInstitution({
      id: payload
    })
    .then(r => {
      return r.data;
    });
};

export const ActionDeleteUserInstitution = (context, payload) => {
  return services.institutions
    .deleteUserInstitution({
      companyId: payload.companyId,
      userId: payload.userId
    })
    .then(r => {
      return r.data;
    });
};

export const ActionDeleteUserInstitutionCompany = (context, payload) => {
  return services.institutions
    .deleteUserInstitutionCompany({
      id: payload.idInstituicao,
      userId: payload.idUser
    })
    .then(r => {
      return r.data;
    });
};

export const ActionUpdateUserInstitution = (context, payload) => {
  const {
    id
  } = payload

  delete payload.id
  return http.put(`companies-users/update/${id}`, payload).then(r => {
    return r.data;
  });
};


export const ActionGetCompanySetorAll = (context, payload) => {
  return services.institutions.getCompanySectorAll({
    id: payload
  }).then(r => {
    return r.data;
  });
};
export const ActionCreateCompanySector = (context, payload) => {
  return services.institutions.createCompanySector(payload).then(r => {
    return r.data;
  });
};
export const ActionUpdateCompanySector = (context, payload) => {
  return services.institutions.updateCompanySector({
    id: payload.id
  }, payload).then(r => {
    return r.data;
  });
};
export const ActionDeleteCompanySector = (context, payload) => {
  return services.institutions.deleteCompanySector({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionGetCompanyCargoAll = (context, payload) => {
  return services.institutions.getCompanyCargoAll({
    id: payload
  }).then(r => {
    return r.data;
  });
};
export const ActionCreateCompanyCargo = (context, payload) => {
  return services.institutions.createCompanyCargo(payload).then(r => {
    return r.data;
  });
};
export const ActionUpdateCompanyCargo = (context, payload) => {
  return services.institutions.updateCompanyCargo({
    id: payload.id
  }, payload).then(r => {
    return r.data;
  });
};
export const ActionDeleteCompanyCargo = (context, payload) => {
  return services.institutions.deleteCompanyCargo({
    id: payload
  }).then(r => {
    return r.data;
  });
};

export const ActionSetIdCompany = ({
  commit
}, payload) => {
  commit(types.SET_ID_COMPANY, payload);
}
