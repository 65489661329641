export default {
  login: { method: 'post', url: 'auth/login' },
  // forgotPass: { method: 'post', url: 'auth/esqueci-senha' },

  functionGet: { method: 'get', url: '@functions/get/{function}' },
  // solicitar dados:
  functionAll: { method: 'get', url: '@functions/all' },
  // lista todas funcoes:

  //Buscar Todos
  all: { method: 'GET', url: 'user/all' },

  // Ativar
  active: { method: 'GET', url: 'user/active' },

  //Buscar Id
  get: { method: 'GET', url: 'user/get{/id}' },

  //Buscar Id

  getTokenFirstAccess: { method: 'GET', url: '/user/first-access{/emailOrCpf}' },

  validateTokenFirstAccess: { method: 'GET', url: '/user/first-access/validate/{token}' },

  //Criar
  create: {
    method: 'POST', url: 'user'
  },

  //Check token
  checkToken: {
    method: 'POST', url: 'auth/refresh'
  },

  //Editar
  update: {
    method: 'PUT', url: 'user/edit{/id}'
  },
  //Inativar
  inactive: { method: 'DELETE', url: 'user{/id}' },
}
