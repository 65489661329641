export default {
  //Buscar Todos
  all: { method: "GET", url: "avails/all" },

  // active: { method: "GET", url: "position/active" },

  //Buscar Id
  get: { method: "GET", url: "avails/get/{id}" },

  //Buscar configurações de variaveis
  getConfigVars: { method: "GET", url: "avail/config/get/{id}" },
  create: {
    method: "POST",
    url: "avails"
  },
  //Buscar configurações de variaveis
  putConfigVars: { method: "PUT", url: "avail/config/update/{id}" },
  create: {
    method: "POST",
    url: "avails"
  },

  //Editar
  update: {
    method: "PUT",
    url: "avails/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "avails/delete{/id}" },

  // --------------------- questoes ---------

  //Buscar Todos
  allQuestion: { method: "GET", url: "question" },

  //Buscar Id
  getQuestion: { method: "GET", url: "question{/id}" },

  //Criar
  createQuestion: {
    method: "POST",
    url: "question"
  },

  //Editar
  updateQuestion: {
    method: "PUT",
    url: "question/{id}"
  },

  //Excluir
  inactiveQuestion: { method: "DELETE", url: "question{/id}" },

  //Associar resposta a questão
  connectReplyQuestion: { method: "POST", url: "question/reply" },

  //Gravar avaliação mongo
  saveMongo: { method: "POST", url: "mdb/avails" },

  //Litar avaliação mongo
  getAvailsMongo: { method: "GET", url: "mdb/avails" },
};
