<template>
  <div>
    <h1>{{text}}</h1>
  </div>
</template>

<script>
export default {
  name:"Empty",

  props:["text"]

}
</script>

<style scoped>
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
