import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetStatusRelatorio = (context, payload) => {
  const {
    program_id,
  } = payload
  return http.post(`avail/result/report/program/${program_id}`).then(r => {
    return r.body;
  });
};
export const ActionGetUsuariosRelatorio = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    program_id,
    company_id,
    executed
  } = payload

  const quantityPerPageQuery = quantityPerPage ? `&limit=${quantityPerPage}` : ''
  const currentPageQuery = currentPage ? `&page=${currentPage}` : ''
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&filterBy=${search}` : ''
  const queryCompanyId = company_id ? `?id_company=${company_id}` : ''
  const queryExecuted = executed ? `&executed=${executed}` : ''

  return http.get(`programs/reports/users/${program_id}${queryCompanyId}${currentPageQuery}${quantityPerPageQuery}${queryExecuted}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionSolicitarRelatorio = (context, payload) => {
  /* Lista todos usuários */
  const {
    program_id,
  } = payload

  const queryProgram_id = program_id ? `${program_id}` : ''

  return http.post(`avail/result/report/program/${queryProgram_id}`).then(r => {
    return r.body;
  });
};

export const ActionGetDownloadLink = (context, payload) => {
  /* Lista todos usuários */
  const {
    report_id,
  } = payload

  return http.get(`avail/result/report/${report_id}/link`).then(r => {
    return r.body;
  });
};
