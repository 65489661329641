// layout admin
import AdminLayout from "../views/Layout/AdminLayout";
import AdminLayoutAcess from "../views/Layout/AdminLayoutAcess";

// auth
const AcessoPorCodigo = () =>
  import("../views/Pages/Auth/AcessoPorCodigo/AcessoPorCodigo.vue");

const PrimeiroAcesso = () =>
  import("../views/Pages/Auth/PrimeiroAcesso/PrimeiroAcesso.vue");

const Download = () =>
  import("../views/Pages/Admin/Download/Download.vue");
const Login = () => import("../views/Pages/Auth/Login/Login.vue");
const HealthCheck = () =>
  import("../views/Pages/Auth/HealthCheck/HealthCheck.vue");
const RecoverAcess = () => import("../views/Pages/Auth/RecoverAcess");

// usuario
const ListUser = () => import("../views/Pages/Admin/User/List");
const CreateUser = () => import("../views/Pages/Admin/User/CreateUser");
const UserImportMassa = () => import("../views/Pages/Admin/User/ImportMassa");
const ListUserPrograms = () => import("../views/Pages/Admin/User/ListUserPrograms");

// questões
const ListQuestion = () => import("../views/Pages/Admin/Questions/List");
const CreateQuestion = () =>
  import("../views/Pages/Admin/Questions/CreateQuestion");

// respostas
const ListAnswer = () => import("../views/Pages/Admin/Answers/List");
const CreateAnswer = () => import("../views/Pages/Admin/Answers/CreateAnswer");

// cargo
const ListPosition = () => import("../views/Pages/Admin/Cargo/List");
const CreatePosition = () =>
  import("../views/Pages/Admin/Cargo/CreatePosition");

// diagnósticos
const ListDiagnostic = () => import("../views/Pages/Admin/Diagnostic/List");
const CreateDiagnostic = () =>
  import("../views/Pages/Admin/Diagnostic/CreateDiagnostic");
const UpdateDiagnostic = () =>
  import("../views/Pages/Admin/Diagnostic/UpdateDiagnostic");

// ações
const ListActions = () => import("../views/Pages/Admin/Actions/List");
const CreateAction = () => import("../views/Pages/Admin/Actions/CreateAction");

// setor
const ListSector = () => import("../views/Pages/Admin/Sector/List");
const CreateSector = () =>
  import("../views/Pages/Admin/Sector/CreateSector/CreateSector");

// instituição
const ListInstitutions = () => import("../views/Pages/Admin/Institutions/List");
const ManterInstitutions = () =>
  import("../views/Pages/Admin/Institutions/CreateInstitutions");

// unidade
const DetailsUnit = () =>
  import("../views/Pages/Admin/Institutions/DetailsUnit");

// listar usuarios vinculados a instituição
const ListUsersInstitution = () =>
  import("../views/Pages/Admin/Institutions/ListUsersInstitution");
const ConnectUserInstitution = () =>
  import("../views/Pages/Admin/Institutions/ConnectUserInstitution");
const ConnectMultipleUserInstitution = () =>
  import("../views/Pages/Admin/Institutions/ConnectMultipleUserInstitution");
// Cids
const ListCids = () => import("../views/Pages/Admin/Cids/List");
const CreateCid = () => import("../views/Pages/Admin/Cids/CreateCid/CreateCid");

// Medicinas
const ListMedicine = () => import("../views/Pages/Admin/Medicina/List");
const CreateMedicine = () =>
  import("../views/Pages/Admin/Medicina/CreateMedicina/CreateMedicina");

// Procedimentos
const ListProcedure = () => import("../views/Pages/Admin/Procedure/List/index");
const CreateProcedure = () =>
  import("../views/Pages/Admin/Procedure/CreateProcedure/CreateProcedure");

// Tipos
const ListType = () => import("../views/Pages/Admin/Type/List/index");
const CreateType = () => import("../views/Pages/Admin/Type/CreateType/CreateType");

// Grupos
const ListGroup = () => import("../views/Pages/Admin/Group/List/index");
const CreateGroup = () => import("../views/Pages/Admin/Group/CreateGroup/CreateGroup");

// Usuários de programa
const ListProgramUser = () =>
  import("../views/Pages/Admin/ProgramUser/List/index");
const Agendamentos = () =>
  import("../views/Pages/Admin/ProgramUser/Agendamentos/Agendamentos");
const AgendaUsuarios = () =>
  import("../views/Pages/Admin/ProgramUser/AgendaUsuarios/AgendaUsuarios");

// Programas
const Programas = () => import("../views/Pages/Admin/Programas/ListProgramas");
const NovoPrograma = () =>
  import("../views/Pages/Admin/Programas/NovoPrograma");
const ProgramLinkInstitution = () =>
  import("../views/Pages/Admin/Programas/LinkInstitution");
const ProgramInstitutions = () =>
  import("../views/Pages/Admin/Programas/InstituicoesPrograma");
const TestePrograma = () =>
  import("../views/Pages/Admin/Programas/TestePrograma");
  const Consentimentos = () => import("../views/Pages/Admin/Programas/Consentimento");

//avaliação
const TestAvail = () => import("../views/Pages/Admin/Avaliacao/TestAvail");
const ListAvaliacao = () =>
  import("../views/Pages/Admin/Avaliacao/ListAvaliacao");
const ConfigAvaliacao = () =>
  import("../views/Pages/Admin/Avaliacao/ConfigAvaliacao");
const ManterAvaliacao = () =>
  import("../views/Pages/Admin/Avaliacao/CreateAvaliacao");

//Pages relatórios
const RelatorioIndividual = () => import("../views/Pages/Admin/RelatorioIndividual/Relatorio.vue");


//Pages Clientes
const UpdateMe = () =>
  import("../views/Pages/Admin/User/UpdateMe/UpdateMe");

// Relatorios
const RelatoriosPopulacional = () =>
  import("../views/Pages/Admin/ReportsPopulation/index.vue");

const RelatorioUsersList = () =>
  import("../views/Pages/Admin/ReportsPopulation/ListUsers.vue");

// Prontuários
const Prontuarios = () => import("../views/Pages/Admin/Prontuario/List");
const CreateProntuario = () => import("../views/Pages/Admin/Prontuario/CreateOrUpdate");

const routes = [
  {
    path: "/",
    component: AdminLayout,
    name: "Home",
    meta: {
      admin: true,
    },
    children: [
      {
        path: "/download",
        name: "Download",
        component: Download,
        meta: {
          free: true,
        },
      },

      {
        path: "/acesso-por-codigo",
        name: "AcessoPorCodigo",
        component: AcessoPorCodigo,
        meta: {
          free: true,
        },
      },

      {
        path: "setor",
        name: "ListSector",
        component: ListSector,
        meta: {
          auth: true,
          admin: true,
          action: "list_sector",
        },
      },
      {
        path: "setor/:id",
        name: "CreateSector",
        component: CreateSector,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "cargos",
        name: "ListPosition",
        component: ListPosition,
        meta: {
          auth: true,
          admin: true,
          action: "list_role",
        },
      },
      {
        path: "cargo/:id",
        name: "CreatePosition",
        component: CreatePosition,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "diagnosticos",
        name: "ListDiagnostic",
        component: ListDiagnostic,
        meta: {
          auth: true,
          admin: true,
          action: "list_diagnostic",
        },
      },

      {
        path: "diagnostico/:id",
        name: "CreateDiagnostic",
        component: CreateDiagnostic,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "diagnostico/editar/:id",
        name: "UpdateDiagnostic",
        component: UpdateDiagnostic,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "acoes",
        name: "ListActions",
        component: ListActions,
        meta: {
          auth: true,
          admin: true,
          action: "list_output",
        },
      },

      {
        name: "CreateAction",
        path: "acao/:id",
        component: CreateAction,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "questao",
        name: "ListQuestion",
        component: ListQuestion,
        meta: {
          auth: true,
          admin: true,
          action: "list_question",
        },
      },
      {
        path: "questao/:id",
        name: "CreateQuestion",
        component: CreateQuestion,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "resposta",
        name: "ListAnswer",
        component: ListAnswer,
        meta: {
          auth: true,
          admin: true,
          action: "list_reply",
        },
      },
      {
        path: "resposta/:id",
        name: "CreateAnswer",
        component: CreateAnswer,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "usuarios",
        name: "ListUser",
        component: ListUser,
        meta: {
          auth: true,
          admin: true,
          action: "list_user",
        },
      },
      {
        path: "usuario/:id/programas",
        name: "ListUserPrograms",
        component: ListUserPrograms,
        meta: {
          auth: true,
          admin: true,
          action: "list_user_programs",
        },
      },

      {
        name: "Relatorio individual usuario",
        path: "usuario/relatorio-individual/:program_id/:user_id/:result_id",
        component: RelatorioIndividual,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "usuario/importar-em-massa",
        name: "ImportMassa",
        component: UserImportMassa,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "usuario/:id/:companyid?",
        name: "CreateUser",
        component: CreateUser,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "instituicao/:id/usuarios",
        name: "ListUsersInstitution",
        component: ListUsersInstitution,
        meta: {
          auth: true,
          admin: true,
        },
        props: {
          nameInstituicao: "",
        },
      },
      {
        path: "instituicao/:id/usuario/:idUser/programas",
        name: "ListUserProgramsInstitution",
        component: ListUserPrograms,
        meta: {
          auth: true,
          admin: true,
          action: "list_user_programs",
        },
      },
      {
        name: "Relatorio individual instituicao",
        path: "instituicao/relatorio-individual/:program_id/:user_id/:result_id",
        component: RelatorioIndividual,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "instituicao/:id/vincular-usuario",
        name: "ConnectUserInstitution",
        component: ConnectUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "instituicao/:id/vincular-multiplos-usuarios",
        name: "ConnectMultipleUserInstitution",
        component: ConnectMultipleUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "unidade/:id/vincular-usuario",
        name: "ConnectUserUnit",
        component: ConnectUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "unidade/:id/vincular-multiplos-usuarios",
        name: "ConnectMultipleUserUnit",
        component: ConnectMultipleUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "instituicao/:id/vincular-usuario/:idUser",
        name: "ConnectUserInstitutionUser",
        component: ConnectUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "unidade/:id/vincular-usuario/:idUser",
        name: "ConnectUserUnitUser",
        component: ConnectUserInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "instituicoes",
        name: "ListInstituicoes",
        component: ListInstitutions,
        meta: {
          auth: true,
          admin: true,
          action: "list_company",
        },
      },
      {
        path: "unidades",
        name: "ListUnidades",
        component: ListInstitutions,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "instituicao/:id/:unidade?",
        name: "CreateInstitutions",
        component: ManterInstitutions,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "unidade/:id",
        name: "DetailsUnit",
        component: DetailsUnit,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        path: "avaliacoes",
        name: "ListAvaliacao",
        component: ListAvaliacao,
        meta: {
          auth: true,
          admin: true,
          action: "list_avail",
        },
      },
      {
        path: "avaliacoes-config/:id",
        name: "ConfigAvaliacao",
        component: ConfigAvaliacao,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "teste-avaliacao/:idAv",
        name: "TestAvail",
        component: TestAvail,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "avaliacao/:id",
        name: "CreateAvaliacao",
        component: ManterAvaliacao,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: 'consentimento/:id',
        name: 'Consentimento',
        component: Consentimentos,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "programas",
        name: "Programas",
        component: Programas,
        meta: {
          auth: true,
          admin: true,
          action: "list_program",
        },
      },
      {
        path: "programa/:id",
        name: "NovoPrograma",
        component: NovoPrograma,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "programa/:id/vinculo/:programinstitutionId",
        name: "ProgramLinkInstitution",
        component: ProgramLinkInstitution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "programa/:id/vinculos",
        name: "ProgramInstitutions",
        component: ProgramInstitutions,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "teste-programa/:id",
        name: "TestePrograma",
        component: TestePrograma,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "cids",
        name: "ListCids",
        component: ListCids,
        meta: {
          auth: true,
          admin: true,
          action: "list_cid",
        },
      },
      {
        name: "CreateCid",
        path: "cid/:id",
        component: CreateCid,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "medicinas",
        name: "ListMedicine",
        component: ListMedicine,
        meta: {
          auth: true,
          admin: true,
          action: "list_medicine",
        },
      },
      {
        name: "CreateMedicine",
        path: "medicina/:id",
        component: CreateMedicine,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "ListProcedure",
        path: "procedimentos",
        component: ListProcedure,
        meta: {
          auth: true,
          admin: true,
          action: "list_medicine",
        },
      },
      {
        name: "CreateProcedure",
        path: "procedimento/:id",
        component: CreateProcedure,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "ListProgramUser",
        path: "programa/:id/usuarios",
        component: ListProgramUser,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "Agendamentos",
        path: "programa/:id/instituicao/:idCompany",
        component: Agendamentos,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "EditAgenda",
        path: "programa/:idProgram/instituicao/:idCompany/:nameInstituicao/agenda/:idAgenda",
        component: AgendaUsuarios,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "RelatorioPopulacional",
        path: "relatorio-populacional/:id",
        component: RelatoriosPopulacional,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "RelatorioUsersList",
        path: "usuarios/relatorio-populacional/:program_id/:id_company/:executed",
        component: RelatorioUsersList,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "Relatorio individual",
        path: "relatorio-individual/:program_id/:user_id",
        component: RelatorioIndividual,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "ListType",
        path: "tipos",
        component: ListType,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "CreateType",
        path: "tipo/:id",
        component: CreateType,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "ListGroup",
        path: "grupos",
        component: ListGroup,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "CreateGroup",
        path: "grupo/:id",
        component: CreateGroup,
        meta: {
          auth: true,
          admin: true,
        },
      },

      {
        name: "Prontuarios",
        path: "prontuarios",
        component: Prontuarios,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        name: "CreateProntuario",
        path: "prontuarios/:id",
        component: CreateProntuario,
        meta: {
          auth: true,
          admin: true,
        },
      },
        {
        path: "meus-dados",
        name: "Meus dados",
        component: UpdateMe,
        meta: {
            auth: true,
          admin: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: AdminLayoutAcess,
    name: "Acesso Sistema",
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          free: true,
        },
      },
      {
        path: "/recover/:token?",
        name: "RecoverAcess",
        component: RecoverAcess,
        meta: {
          free: true,
        },
      },
      {
        path: "/primeiro-acesso/:token?",
        name: "PrimeiroAcesso",
        component: PrimeiroAcesso,
        meta: {
          free: true,
        },
      },
    ]
  },

  {
    path: "/health-check",
    name: "HealthCheck",
    component: HealthCheck,
    meta: {
      free: true,
    },
  },

  {
    path: "/:pathMath(.*)*",
    redirect: {
      name: "Login",
    },
  },
];

export default routes;
