export default {
  //Buscar Todos
  all: { method: "GET", url: "type-user/all" },

  //Buscar Id
  get: { method: "GET", url: "type-user/get{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "type-user"
  },

  //Editar
  update: {
    method: "PUT",
    url: "type-user/update/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "type-user/delete{/id}" }
};
