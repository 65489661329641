<template>
  <div class="">
    <notifications></notifications>
    <side-bar v-if="hasToken && !$route.meta.free">
      <template slot="links">
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (havePathInScreens('/instituicoes') &&
              getUser.tipoCompany == 'MASTER')
          "
          :link="{
            name: 'Instituições',
            icon: 'fa fa-building',
            path: '/instituicoes',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            (getUser.session_type == 'admin' ||
              getUser.session_type == 'medico') &&
            havePathInScreens('/unidades')
          "
          :link="{
            name: 'Unidades',
            icon: 'fa fa-building',
            path: '/unidades',
          }"
        />

        <sidebar-item
          v-if="
            getUser.tipoCompany == 'MASTER' &&
            havePathInScreens('/setor') &&
            getUser.session_type != 'master'
          "
          :link="{
            name: 'Setores',
            icon: 'fa fa-industry',
            path: '/setor',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            getUser.tipoCompany == 'MASTER' &&
            havePathInScreens('/cargos') &&
            getUser.session_type != 'master'
          "
          :link="{
            name: 'Cargos',
            icon: 'fa fa-id-card',
            path: '/cargos',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            getUser.session_type == 'master' || havePathInScreens('/usuarios')
          "
          :link="{
            name: 'Usuários',
            icon: 'fa fa-users',
            path: '/usuarios',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            getUser.session_type == 'master' || havePathInScreens('/programas')
          "
          :link="{
            name: 'Programas',
            icon: 'fa fa-file',
            path: '/programas',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' &&
              havePathInScreens('/avaliacoes'))
          "
          :link="{
            name: 'Avaliações',
            icon: 'fa fa-tasks',
            path: '/avaliacoes',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' && havePathInScreens('/cids'))
          "
          :link="{
            name: 'CIDs',
            icon: 'fas fa-list',
            path: '/cids',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' && havePathInScreens('/medicinas'))
          "
          :link="{
            name: 'Medicamentos',
            icon: 'fas fa-capsules',
            path: '/medicinas',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' &&
              havePathInScreens('/procedimentos'))
          "
          :link="{
            name: 'Procedimentos',
            icon: 'fas fa-procedures',
            path: '/procedimentos',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' && havePathInScreens('/tipos'))
          "
          :link="{
            name: 'Tipos',
            icon: 'fas fa-users',
            path: '/tipos',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' && havePathInScreens('/grupos'))
          "
          :link="{
            name: 'Grupos',
            icon: 'fas fa-users',
            path: '/grupos',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="
            getUser.session_type == 'master' ||
            (getUser.session_type == 'admin' &&
              havePathInScreens('/prontuarios'))
          "
          :link="{
            name: 'Prontuários',
            icon: 'fas fa-book',
            path: '/prontuarios',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-content">
      <dashboard-navbar v-if="hasToken && !$route.meta.free" :type="$route.meta.navbarType"></dashboard-navbar>

      <div
        :style="{marginTop: hasToken && !$route.meta.free ? '-210px' : 0}"
        @click="$sidebar.displaySidebar(false)"
      >
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "../DashboardNavbar.vue";
import { FadeTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardNavbar,
    FadeTransition,
  },
  computed: {
    ...mapGetters("auth", ["getUser", "hasToken"]),

    screens() {
      let screensList = [];

      let { types } = this.getUser;

      // if (!!types)
      (types || []).map((type) => {
        type.permissions.map((group) => {
          group.functionalities.map((funcy) => {
            let { screens } = funcy;

            if (
              !screensList.find(
                (f) => screens.web.filter((x) => x == f).length > 0
              )
            ) {
              screensList = screensList.concat(screens.web);
            }
          });
        });
      });

      screensList = screensList.filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

      return screensList;
    },
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    havePathInScreens(path) {
      return !!this.screens.filter((f) => f == path).length;
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
