<template>
  <div class="preloader" v-if="loading">
    <!-- <p class="text-white">{{ text || "Carregando..." }}</p> -->
    <Spinner />
  </div>
</template>

<script>
export default {
  name: "pre-loader",
  props: {
    text: String,
    loading: Boolean
  }
};
</script>

<style scoped>
/* pre-loader */
.preloader {
  position: fixed;
  top: 0;
  background: #000;
  opacity: 0.8;
  width: 100vw;
  height: 100%;
  z-index: 999999999999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
