import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetSectorsPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
    company_id
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''
  const idQuery = company_id ? `&company_id=${company_id}` : ''


  return http.get(`company-sectors/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}${idQuery}`).then(r => {
    return r.body;
  });
};

export const ActionGetSector = (context, payload) => {
  /* Pega usuario por id */
  return services.sector.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateSector = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`company-sectors/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateSector = (context, payload) => {
  /*Criar usuario*/
  return services.sector.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveSector = (context, payload) => {
  return services.sector.inactive({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUploadExcel = (context, payload) => {
  const {
    company_id,
    file
  } = payload
  const formData = new FormData()
  formData.append('data', file)
  return http.post(`company-sectors/create/lote/company/${company_id}`, formData).then(r => {
    return r;
  });
};