import Vue from "vue";
import VueResource from "vue-resource";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import store from "./store";
import VueTheMask from "vue-the-mask";
import { vSelect } from "vue-select";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import "vue-select/dist/vue-select.css";
import "./assets/css/global.css";
import "vue-swatches/dist/vue-swatches.css";
import "floating-vue/dist/style.css";
import FloatingVue from "floating-vue";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { ColorPicker, ColorPanel } from "one-colorpicker";

// carregar formulas quill editor
import "katex/dist/katex.css";
import katex from 'katex'
window.katex = katex

import router from "./routes/router";

require("./plugins");

Vue.use(FloatingVue);
Vue.use(DashboardPlugin);
Vue.use(VueResource);
Vue.use(VueTheMask);
Vue.use(ColorPanel);
Vue.use(ColorPicker);
Vue.component("cropper", Cropper);
Vue.component("v-select", vSelect);
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);
Vue.component("Datepicker", VueDatepickerUi);

/* eslint-disable no-new */
window._Vue = new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
});
