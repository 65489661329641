export default {
  //Buscar Todos
  all: { method: "GET", url: "programs/all" },

  // Ativar
  // active: { method: "GET", url: "position/active" },

  //Buscar Id
  get: { method: "GET", url: "programs/get{/id}" },
  //Buscar Id e dados reduzidos
  getLimited: { method: "GET", url: "programs/get/program{/id}" },

  //Criar
  create: {
    method: "POST",
    url: "programs"
  },

  //Editar
  update: {
    method: "PUT",
    url: "programs/{id}"
  },

  //Excluir
  inactive: { method: "DELETE", url: "programs/delete{/id}" },

  getProgramInstitution: { method: "GET", url: "company-programs/get{/id}" },


  createProgramInstitution: { method: "POST", url: "company-programs" },

  inactiveProgramInstitution: { method: "DELETE", url: "company-programs/delete{/id}" },


  //frequencia do programa
  getProgramFrequency: { method: "GET", url: "company-programs/frequency{/id}" },


  //vincular usuarios ao programa
  linkUsers: { method: "post", url: "user-programs/multiple" }
};
