import services from "@/http";
import { http } from "@/http";

export const ActionGetPermissionGroupsPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''


  return http.get(`permission-groups/paginate?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};
export const ActionAllPermissions = (context, payload) => {
  return http.get(`permission-groups/all?type_id=${payload}`).then(r => {
    return r.body;
  });
};

export const ActionGetPermissionGroups = (context, payload) => {
  /* Pega usuario por id */
  return http.get(`permission-groups/get/${payload}`).then(r => {
    return r.body;
  });
};

export const ActionUpdatePermissionGroups = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`permission-groups/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreatePermissionGroups = (context, payload) => {
  /*Criar usuario*/
  // return services.permissionGroup.create(payload).then(r => {
  //   return r.data;
  // });
  return http.post(`permission-groups`, payload).then(r => {
    return r.body;
  });
};

export const ActionInactivePermissionGroups = (context, payload) => {

  return http.delete(`permission-groups/delete/${payload}`).then(r => {
    return r.body;
  });
};