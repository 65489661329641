import { store as auth } from "../views/Pages/Auth/Login";
import { store as users } from "../views/Pages/Admin/User";
import { store as institutions } from "../views/Pages/Admin/Institutions";
import { store as position } from "../views/Pages/Admin/Cargo";
import { store as sector } from "../views/Pages/Admin/Sector";
import { store as avail } from "../views/Pages/Admin/Avaliacao";
import { store as question } from "../views/Pages/Admin/Questions";
import { store as reply } from "../views/Pages/Admin/Answers";
import { store as preloader } from "../components/PreLoader";
import { store as programa } from "../views/Pages/Admin/Programas";
import { store as diagnostics } from "../views/Pages/Admin/Diagnostic";
import { store as actions } from "../views/Pages/Admin/Actions";
import { store as cids } from "../views/Pages/Admin/Cids";
import { store as medicines } from "../views/Pages/Admin/Medicina";
import { store as procedure } from "../views/Pages/Admin/Procedure";
import { store as programuser } from "../views/Pages/Admin/ProgramUser";
import { store as relatorioIndividual } from "../views/Pages/Admin/RelatorioIndividual";
import { store as relatorioPopulacional } from "../views/Pages/Admin/ReportsPopulation";
import { store as prontuario } from "../views/Pages/Admin/Prontuario";
import { store as infoRoute } from "./infoRoute";


import { store as typeUser } from "../views/Pages/Admin/Type";
import { store as permissionGroup, funcionalidadeStore as functionalities } from "../views/Pages/Admin/Group";

// // ------- store area clientes ----------
// import { store as signUp } from "../views/Pages/Clients/Signup";
// import { store as home } from "../views/Pages/Clients/Home";

export default {
  infoRoute,
  relatorioPopulacional,
  relatorioIndividual,
  programuser,
  actions,
  diagnostics,
  preloader,
  auth,
  users,
  prontuario,
  institutions,
  position,
  sector,
  avail,
  question,
  reply,
  programa,
  cids,
  medicines,
  procedure,
  typeUser,
  permissionGroup,
  functionalities,
};
