import { setBearerToken } from '@/http'
// TOKEN
export const setHeaderToken = token => setBearerToken(token)
export const getLocalToken = () => localStorage.getItem('token')
export const deleteLocalToken = () => localStorage.removeItem('token')
export const setLocalToken = token => localStorage.setItem('token', token)

// USER
export const getLocalUsuario = () => JSON.parse(localStorage.getItem('usuario'))
export const deleteLocalUsuario = () => localStorage.removeItem('usuario')
export const setLocalUsuario = usuario => {
  const localuser = getLocalUsuario()

  if (localuser && localuser.length >= 10) {
    localStorage.setItem('usuario', usuario)
  } else {
    localStorage.setItem('usuario', usuario)
  }
}
