<template>
  <span
    label="Spinning"
    :style="`width: ${size ? size : '3rem'}; height: ${size ? size : '3rem'}`"
    :class="classes"
  ></span>
</template>

<script>
export default {
  name: "Spinner",
  props: ["size","classes"]
};
</script>

<style scoped>
span {
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  animation: spinner infinite 0.5s linear;
  color: #fff;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
