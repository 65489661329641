var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('notifications'),(_vm.hasToken && !_vm.$route.meta.free)?_c('side-bar',[_c('template',{slot:"links"},[(
          _vm.getUser.session_type == 'master' ||
          (_vm.havePathInScreens('/instituicoes') &&
            _vm.getUser.tipoCompany == 'MASTER')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Instituições',
          icon: 'fa fa-building',
          path: '/instituicoes',
        }}}):_vm._e(),(
          (_vm.getUser.session_type == 'admin' ||
            _vm.getUser.session_type == 'medico') &&
          _vm.havePathInScreens('/unidades')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Unidades',
          icon: 'fa fa-building',
          path: '/unidades',
        }}}):_vm._e(),(
          _vm.getUser.tipoCompany == 'MASTER' &&
          _vm.havePathInScreens('/setor') &&
          _vm.getUser.session_type != 'master'
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Setores',
          icon: 'fa fa-industry',
          path: '/setor',
        }}}):_vm._e(),(
          _vm.getUser.tipoCompany == 'MASTER' &&
          _vm.havePathInScreens('/cargos') &&
          _vm.getUser.session_type != 'master'
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Cargos',
          icon: 'fa fa-id-card',
          path: '/cargos',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' || _vm.havePathInScreens('/usuarios')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Usuários',
          icon: 'fa fa-users',
          path: '/usuarios',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' || _vm.havePathInScreens('/programas')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Programas',
          icon: 'fa fa-file',
          path: '/programas',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' &&
            _vm.havePathInScreens('/avaliacoes'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Avaliações',
          icon: 'fa fa-tasks',
          path: '/avaliacoes',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' && _vm.havePathInScreens('/cids'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'CIDs',
          icon: 'fas fa-list',
          path: '/cids',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' && _vm.havePathInScreens('/medicinas'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Medicamentos',
          icon: 'fas fa-capsules',
          path: '/medicinas',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' &&
            _vm.havePathInScreens('/procedimentos'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Procedimentos',
          icon: 'fas fa-procedures',
          path: '/procedimentos',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' && _vm.havePathInScreens('/tipos'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Tipos',
          icon: 'fas fa-users',
          path: '/tipos',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' && _vm.havePathInScreens('/grupos'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Grupos',
          icon: 'fas fa-users',
          path: '/grupos',
        }}}):_vm._e(),(
          _vm.getUser.session_type == 'master' ||
          (_vm.getUser.session_type == 'admin' &&
            _vm.havePathInScreens('/prontuarios'))
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Prontuários',
          icon: 'fas fa-book',
          path: '/prontuarios',
        }}}):_vm._e()],1)],2):_vm._e(),_c('div',{staticClass:"main-content"},[(_vm.hasToken && !_vm.$route.meta.free)?_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}):_vm._e(),_c('div',{style:({marginTop: _vm.hasToken && !_vm.$route.meta.free ? '-210px' : 0}),on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }