import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetCidsPagination = (context, payload) => {
  /* Lista todos usuários */
  const {
    quantityPerPage,
    currentPage,
    search,
    orderBy,
    orderColumn,
  } = payload
  const queryOrderBy = orderBy ? `&orderBy=${orderBy}` : ''
  const queryOrderColumn = orderColumn ? `&orderColumn=${orderColumn}` : ''
  const querySearch = search ? `&search=${search}` : ''


  return http.get(`cids/all?page=${currentPage}&quantity=${quantityPerPage}${querySearch}${queryOrderColumn}${queryOrderBy}`).then(r => {
    return r.body;
  });
};

export const ActionGetCids = (context, payload) => {
  /* Lista todos usuários */

  return http.get(`cids/collection`).then(r => {
    return r.body;
  });
};

export const ActionGetCid = (context, payload) => {
  /* Pega usuario por id */
  return services.cids.get({ id: payload }).then(r => {
    return r.data;
  });
};

export const ActionUpdateCid = (context, payload) => {
  /* atualizar usuarios */
  return http.put(`cids/update/${payload.id}`, payload).then(r => {
    return r.data;
  });
};

export const ActionCreateCid = (context, payload) => {
  /*Criar usuario*/
  return services.cids.create(payload).then(r => {
    return r.data;
  });
};

export const ActionInactiveCid = (context, payload) => {
  return services.cids.inactive({ id: payload }).then(r => {
    return r.data;
  });
};